import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.min';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import firebase from 'firebase';
import './res/css/main.css';
import { BrowserRouter, Route } from 'react-router-dom';

firebase.initializeApp({
	apiKey: process.env.REACT_APP_apiKey,
	authDomain: process.env.REACT_APP_authDomain,
	databaseURL: process.env.REACT_APP_databaseURL,
	projectId: process.env.REACT_APP_projectId,
	storageBucket: process.env.REACT_APP_storageBucket,
	messagingSenderId: process.env.REACT_APP_messagingSenderId,
	appId: process.env.REACT_APP_appId,
});

firebase
	.firestore()
	.enablePersistence({ experimentalTabSynchronization: true })
	.catch((err) => {
		if (err.code === 'failed-precondition') {
			// Multiple tabs open, persistance can only be enabled in one tab at a time
		} else if (err.code === 'unimplemented') {
			// The current browser doesn't support all the features required for persistance
		}
	});

// All subsquent queries will use persistance

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Route path='/'>
				<App />
			</Route>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
