import React, { useEffect, useState } from 'react';
import { verifyOtp } from '../../services/user';
import Button from '../shared/Button';
import { EMAIL_KEY } from '../../services/constants';
import { Link, Redirect } from 'react-router-dom';

export default function VerifyOtp() {
	const [code, setCode] = useState('');
	const [error, setError] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [redirect, setRedirect] = useState(false);
	const [sessionError, setSessionError] = useState(null);

	useEffect(() => {
		if (!JSON.parse(sessionStorage.getItem(EMAIL_KEY))) {
			setSessionError('Password reset session expired');
		}
	}, []);

	const submit = (e) => {
		e.preventDefault();
		setSubmitting(true);
		setError(null);
		verifyOtp({
			code: code,
			email: JSON.parse(sessionStorage.getItem(EMAIL_KEY)),
		})
			.then(({ success, message }) => {
				success ? setRedirect(true) : setError(message);
				setSubmitting(false);
			})
			.catch(() => {
				setError(
					'An unknown error occured while trying to reset your password.'
				);
			});
	};

	if (redirect) {
		return (
			<Redirect to={{ pathname: '/login', state: { fromPassReset: true } }} />
		);
	}

	return (
		<div className='tg-container-white'>
			<h3>Confirm OTP</h3>
			<p>
				Please enter the One Time Password that has been sent to your phone to
				receive email reset instructions.
			</p>

			{error ? <p className='alert alert-danger text-center'>{error}</p> : null}
			{sessionError ? (
				<p className='alert alert-danger text-center'>{sessionError}</p>
			) : null}

			<form onSubmit={submit}>
				<div className='form-group'>
					<label>
						Enter code <span className='text-danger'>*</span>
					</label>
					<input
						className='form-control'
						value={code}
						onChange={(e) => setCode(e.target.value)}
						required
						readOnly={Boolean(sessionError)}
					/>
				</div>

				<Link to='/login' className='tg-text-blue' style={{ marginTop: '1em' }}>
					Have an account? Log in
				</Link>

				<Button
					disabled={Boolean(sessionError)}
					submitting={submitting}
					text='Verify'
					activeText='Verifying...'
				/>
			</form>
		</div>
	);
}
