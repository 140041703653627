import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

export default function Loading() {
	return (
		<div className='text-center'>
			<Spinner animation='grow' variant='danger' style={{ marginTop: '40%' }} />
			<p>Loading.....</p>
		</div>
	);
}
