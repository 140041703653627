import jwtDecode from 'jwt-decode';
import {
	AUTH_TOKENS,
	LOGIN_URL,
	RESET_PASSWORD,
	VERIFY_OTP,
} from './constants';

const jsonHeaders = { 'Content-Type': 'application/json' };

export async function loginService(data) {
	const resp = await fetch(`${LOGIN_URL}`, {
		method: 'POST',
		body: JSON.stringify(data),
		headers: { ...jsonHeaders },
	});
	return await resp.json();
}

export function getLocalAuthTokens() {
	let tokens = localStorage.getItem(AUTH_TOKENS);
	return (tokens = tokens ? JSON.parse(tokens) : null);
}

export function setLocalAuthTokens(tokens) {
	localStorage.setItem(AUTH_TOKENS, JSON.stringify(tokens));
}

export function localAuthService() {
	let tokens = getLocalAuthTokens();
	return tokens
		? { isLoggedIn: true, user: jwtDecode(tokens.access) }
		: { isLoggedIn: false, user: {} };
}

export function clearAuthTokens() {
	localStorage.removeItem(AUTH_TOKENS);
	localStorage.removeItem('tungaUserOfficeAddress');
}

export async function resetPassword(data) {
	const resp = await fetch(`${RESET_PASSWORD}`, {
		method: 'POST',
		body: JSON.stringify(data),
		headers: { ...jsonHeaders },
	});
	return await resp.json();
}

export async function verifyOtp(data) {
	const resp = await fetch(`${VERIFY_OTP}`, {
		method: 'POST',
		body: JSON.stringify(data),
		headers: { ...jsonHeaders },
	});
	return await resp.json();
}
