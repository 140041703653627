import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import {
	clearAuthTokens,
	localAuthService,
	loginService,
	setLocalAuthTokens,
} from '../services/user';

export const userContext = createContext();

export function UserContextProvider({ children }) {
	const localAuth = localAuthService();

	const [user, setUser] = useState(localAuth.user);
	const [isLoggedIn, setIsLoggedIn] = useState(localAuth.isLoggedIn);
	const [connError, setConnError] = useState(false);
	const [logingIn, setLogingIn] = useState(false);
	const [loginError, setLoginError] = useState(false);

	const loginUser = (data) => {
		setLogingIn(true);
		setLoginError(false);
		setConnError(false);
		loginService(data)
			.then(({ access, refresh }) => {
				if (access) {
					setLocalAuthTokens({ access: access, refresh: refresh });
					setIsLoggedIn(true);
					setUser(jwtDecode(access));
				} else {
					// Logged in failed with the provided credentials
					setLoginError(true);
				}
				setLogingIn(false);
			})
			.catch((err) => {
				// Failed to connect to api
				setLogingIn(false);
				setConnError(true);
			});
	};

	const logoutUser = () => {
		clearAuthTokens();
		setIsLoggedIn(false);
		setLoginError(false);
		setConnError(false);
		setUser({});
	};

	return (
		<userContext.Provider
			value={{
				user: user,
				isLoggedIn: isLoggedIn,
				connError: connError,
				loginError: loginError,
				logingIn: logingIn,
				setUser,
				loginUser: loginUser,
				logoutUser: logoutUser,
			}}
		>
			{children}
		</userContext.Provider>
	);
}

UserContextProvider.propTypes = {
	children: PropTypes.any,
};
