import React from 'react';
import PropTypes from 'prop-types';

export default function Button({ submitting, text, activeText, disabled }) {
	return (
		<button
			className='tg-btn-blue'
			type='submit'
			data-testid='button'
			disabled={disabled ? disabled : submitting ? true : false}
		>
			{submitting ? (
				<>
					<span
						className='spinner-border spinner-border-sm'
						role='status'
						aria-hidden='true'
						data-testid='spinner'
					/>{' '}
				</>
			) : null}
			<span className='sr-only' data-testid='text'>
				{submitting ? activeText : text}
			</span>
		</button>
	);
}

Button.propTypes = {
	submitting: PropTypes.bool.isRequired,
	disabled: PropTypes.bool,
	text: PropTypes.string.isRequired,
	activeText: PropTypes.string.isRequired,
};
