import React from 'react';
import PropTypes from 'prop-types';

const Desktop = ({ children }) => {
	return (
		<div
			className='mx-auto position-relative'
			style={{ maxWidth: '480px', width: '100%' }}
		>
			{children}
		</div>
	);
};

Desktop.propTypes = {
	children: PropTypes.node,
};

export default Desktop;
