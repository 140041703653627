import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { HOME_URL, QRCODE_URL, PRINT_URL } from '../../util/constants';
import LogoutModal from './LogoutModal';

function isActive(url) {
	return url === window.location.pathname;
}

function Navigation({ children }) {
	const [show, setShow] = useState(false);

	useEffect(() => {}, [window.location.pathname]);

	const tabs = [
		{
			route: '/id_card',
			icon: (
				<span
					className={
						isActive(HOME_URL)
							? 'tg-tab-icon tg-home tg-home-active'
							: 'tg-tab-icon tg-home'
					}
				></span>
			),
			label: 'Home',
		},
		{
			route: '/barcode',
			icon: (
				<span
					className={
						isActive(QRCODE_URL)
							? 'tg-tab-icon tg-qrcode tg-qrcode-active'
							: 'tg-tab-icon tg-qrcode'
					}
				></span>
			),
			label: 'Barcode',
		},
		{
			route: '/print',
			icon: (
				<span
					className={
						isActive(PRINT_URL)
							? 'tg-tab-icon tg-print tg-print-active'
							: 'tg-tab-icon tg-print'
					}
				></span>
			),
			label: 'Print',
		},
	];

	return (
		<>
			<LogoutModal show={show} setShow={setShow} />
			{children}
			{/* Bottom Tab Navigator*/}
			<nav
				className='navbar fixed-bottom navbar-light tg-navbar'
				role='navigation'
				style={{ zIndex: 1000, backgroundColor: 'white' }}
			>
				<Nav className='w-100 justify-center align-items-center'>
					<div className='row tg-balance mx-auto'>
						{tabs.map((tab, index) => (
							<div className='col-3' key={`tab-${index}`}>
								<NavLink
									to={tab.route}
									className='nav-link tg-nav-item'
									activeClassName='active'
								>
									{/* <div className='row justify-content-center align-items-center'>*/}
									{tab.icon}
									<div className='tg-tab-label'>{tab.label}</div>
									{/* </div> */}
								</NavLink>
							</div>
						))}
						<div className='col-3'>
							<span
								className='nav-link tg-nav-item'
								onClick={() => setShow(true)}
							>
								<span className='tg-tab-icon tg-logout'></span>
								<div className='tg-tab-label'>Logout</div>
								{/* </div> */}
							</span>
						</div>
					</div>
				</Nav>
			</nav>
		</>
	);
}

Navigation.propTypes = {
	children: PropTypes.any,
};

export default Navigation;
