import React, { useEffect, Suspense, lazy } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import Navigation from './components/shared/Navigation';
import { HOME_URL, PRINT_URL, QRCODE_URL } from './util/constants';
import ProtectedRoute from './components/shared/ProtectedRoute';
import { UserContextProvider } from './context/userContext';
import Loading from './components/shared/Loading';
import VerifyOtp from './components/account/VerifyOtp';
import Desktop from './components/Desktop';

const Home = lazy(() => import('./components/Home'));
const Login = lazy(() => import('./components/account/Login'));
const ScanQrcode = lazy(() => import('./components/qrcode/ScanQrcode'));
const IDCard = lazy(() => import('./components/account/IDCard'));
const Barcode = lazy(() => import('./components/qrcode/Barcode'));
const Print = lazy(() => import('./components/print/Print'));
const Details = lazy(() => import('./components/account/Details'));
const ForgotPassword = lazy(() =>
	import('./components/account/ForgotPassword')
);

function App() {
	let history = useHistory();

	useEffect(() => {
		history.listen(() => {
			if (window.swUpdateReady) {
				window.swUpdateReady = false;
				window.stop;
				window.location.reload();
			}
		});
	}, []);

	return (
		<ToastProvider>
			<UserContextProvider>
				<Suspense fallback={<Loading />}>
					<Desktop>
						<Switch>
							<Route exact path='/' component={Home} />
							<Route path='/login' component={Login} />
							<Route path='/scan_qrcode' component={ScanQrcode} />
							<Route path='/forgot_password' component={ForgotPassword} />
							<Route path='/verify_otp' component={VerifyOtp} />
							<Route exact path='/user/details' component={Details} />
							<Navigation>
								<ProtectedRoute path={HOME_URL} component={IDCard} />
								<ProtectedRoute path={QRCODE_URL} component={Barcode} />
								<ProtectedRoute path={PRINT_URL} component={Print} />
							</Navigation>
						</Switch>
					</Desktop>
				</Suspense>
			</UserContextProvider>
		</ToastProvider>
	);
}

export default App;
