import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { userContext } from '../../context/userContext';

function LogoutModal({ show, setShow }) {
	const { logoutUser } = useContext(userContext);

	return (
		<>
			<Modal
				show={show}
				onHide={() => setShow(false)}
				className='d-flex justify-content-center'
				dialogClassName='modal-90w tg-modal'
				aria-labelledby='example-custom-modal-styling-title'
			>
				<Modal.Header className='tg-bb-0' closeButton></Modal.Header>
				<Modal.Body>
					<span className='tg-hd-txt tg-mgb-10'>Logout</span>

					<p style={{ color: '#54596E' }}>Are you sure you want to logout?</p>

					<div id='HASH' className='tg-mgb-20'>
						<button
							className='btn tg-btn-blue-outline tg-w50'
							onClick={logoutUser}
						>
							Yes
						</button>
						<button
							className='btn tg-btn-blue tg-w50'
							style={{ float: 'right' }}
							onClick={() => setShow(false)}
						>
							Cancel
						</button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}

LogoutModal.propTypes = {
	show: PropTypes.bool.isRequired,
	setShow: PropTypes.func.isRequired,
};

export default LogoutModal;
