import reportWebVitals from './reportWebVitals';

const rWV = (onPerfEntry) => {
	if (onPerfEntry && onPerfEntry instanceof Function) {
		reportWebVitals.then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
			getCLS(onPerfEntry);
			getFID(onPerfEntry);
			getFCP(onPerfEntry);
			getLCP(onPerfEntry);
			getTTFB(onPerfEntry);
		});
	}
};

export default rWV;
